import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';




import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);

  useEffect(() => {
    const fetchLocationData = () => {
      fetch('/api/location')
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setCountry(decodeURIComponent(data.country));
          setCity(decodeURIComponent(data.city));
          
          // Remove numbers from city and country names
          const cleanCity = data.city.replace(/[0-9]/g, '');
          const cleanCountry = data.country.replace(/[0-9]/g, '');
  
          const address = `${encodeURIComponent(cleanCity)}, ${encodeURIComponent(cleanCountry)}`;
          const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBTT1Epe-qKRGcLSPos2waBZ-2M5dkMfew`;
          console.log("URL Requested: ", url); // Log the URL
          return axios.get(url);
        })
        .then(response => {
          console.log("Google Geocoding API Response: ", response.data); // Log the full response
          const results = response.data.results;
          if (results.length > 0) {
            const addressComponents = results[0].address_components;
            const stateObj = addressComponents.find(component => component.types.includes('administrative_area_level_1'));
            if (stateObj) {
              setRegion(stateObj.long_name);
            }
          }
          setFetchAttempted(true);
        })
        .catch(error => {
          console.error('Fetch error:', error);
          setCountry('Unavailable');
          setRegion('');
          setCity('');
          setFetchAttempted(true);
        });
    };
  
    if (!region && !fetchAttempted) {
      fetchLocationData();
    }
  }, [region]); // Dependency on region

  const imageUrls = [
    'https://i.ibb.co/cCbQqhx/cropped-photo-2024-04-17-19-03-55.jpg',
    'https://i.ibb.co/N6sfGrd/cropped-photo-2024-04-17-19-03-54.jpg',
    'https://i.ibb.co/YBhkPdn/cropped-photo-2024-04-17-19-03-53.jpg',
    'https://i.ibb.co/5LZTfCF/cropped-photo-2024-04-17-19-03-51.jpg',
    'https://i.ibb.co/55Bmt4t/cropped-photo-2024-04-17-19-03-50.jpg',
    'https://i.ibb.co/2NHgz11/cropped-image-2024-04-17-20-40-39.png',
    'https://i.ibb.co/dmJ6sFK/cropped-photo-2024-04-17-19-03-26.jpg',
    'https://i.ibb.co/B3Y2hsr/cropped-image-2024-04-17-20-41-52.png',
    'https://i.ibb.co/GR513hZ/cropped-image-2024-04-17-20-41-19.png',
    'https://i.ibb.co/5WTRhSw/cropped-image-2024-04-17-20-42-56.png',
    'https://i.ibb.co/LhS0j21/cropped-image-2024-04-17-20-44-21.png'
  ];

  return (

    <div className="container">
    <img 
      src="https://assets.cdn.filesafe.space/CJkDD96k2jOfuywnYhgP/media/65a51f792aaf1b036608af13.png" 
      alt="Header Image"
      className="header-image"
    />
    <Swiper
      effect={'coverflow'}
      grabCursor={true}
      centeredSlides={true}
      loop={true}
      slidesPerView={'2'}
      coverflowEffect={{
      rotate: 15,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
     }}
    autoplay={{ // Corrected from autoPlay to autoplay
      delay: 2000,
      disableOnInteraction: false,
    }}
    breakpoints={{
      // Screens smaller than 640px
      640: {
        slidesPerView: 2,
      },
      // Larger screens
      640: {
        slidesPerView: 3, // or whatever number you prefer
      },
    }}
    modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
    className="swiper-container"
  >
    {imageUrls.map(url => (
      <SwiperSlide key={url} className="swiper-slide">
        <img src={url} alt="slide_image"/>
      </SwiperSlide>
    ))}
      <div className="slider-controler">
        <div className="swiper-pagination"></div>
      </div>
    </Swiper>

    <div className='textContainer'>
      <img src='https://i.ibb.co/sKGFh09/cropped-image-2024-04-17-20-44-21-modified.png'/>
      <section>
          <div className='flexRow'>Rosy🌸 <div className='buttonStyling'>Online Now</div></div>
          <div className='flexRow'>
            <span>
            Your 19yo petite student currently in {city}, {region}   {country && <img 
                src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`} 
                alt={`${country} Flag`}
                style={{ width: '20px', height: '15px', verticalAlign: 'middle' }}
              />} 
          
              
            </span>
          </div>


      </section>
    </div>

    <a href="https://onlyfans.com/rosyxoxvip/c30" id="customButton">
      Send me a message
    </a>

    <p className="exclusiveContent">
      Exclusive content 💖 FREE NOW for a limited time only!<br/><br/>
      Looking for content partners, DM me on OnlyFans to film w/ me 🎥💓
    </p>
    <Analytics/>
  </div>

  );
}

export default App;
